<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click.self="close">
      <div class="modal-container text-right">
        <button @click="close">
          <fa icon="xmark" size="lg"/>
        </button>
        <div class="modal-body">
					<p class="text-xl text-red-500">Opravdu si přejete odstranit příjemce?</p>
					<div class="editable-name mb-3">
						{{ receiverName }}
					</div>
        </div>
        <div class="flex justify-center">
					<button class="edit-button edit-button-back mr-2 bg-blue-500" @click="close">Zrušit</button>
					<button class="edit-button edit-button-remove ml-2 bg-red-500" @click="removeReceiver()">Odstranit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'receiverName',
		'receiverId',
  ],
  methods: {
    close(){
        this.$emit('closed')
    },
    // get receiver name based on receivers id
    getReceiversName(rec_id) {
      return this.$store.getters.receivers.filter(
        (rec) => rec.receivers_id == rec_id
      )[0].name.trim();
    },
		async removeReceiver() {
			this.close();

      const payload = {
        rec_id: this.receiverId
      }
      await this.$store.dispatch('removeReceiver', payload);
      await this.$store.dispatch('getReceivers');
      await this.$store.dispatch('setFilterResetNeeded', true);
    }
  }
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 40%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.modal-body {
  text-align: center;
}

.edit-button {
  display: block;
  width: 8rem;
  margin-bottom: 0.25rem;
  color: white;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
  text-transform: uppercase;
}

/* .remove-button {
	background-color: rgb(59, 130, 246);
} */


.edit-button-remove:hover {
	background-color: rgb(220, 38, 38);
}

.edit-button-back:hover {
  background-color: rgb(29, 78, 216);
}

.edit-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
</style>