<template>
  <div class="mb-3">
    <div class="flex justify-between w-full mb-3">
      <div class="flex w-full">
        <input
          class="search-input"
          type="text"
          v-model="search"
          @input="sendFilteredReceivers"
          placeholder="Hledat příjemce nebo kategorii"
        />
        <div class="bg-transparent -ml-8 mt-2.5">
          <fa icon="magnifying-glass" class="search-icon"/>
        </div>
      </div>
      <button @click="sortReceivers" class="search-sort-button">
        Seřadit abecedně
        <fa v-if="sortDesc" icon="arrow-down" class="ml-4" />
        <fa v-else icon="arrow-up" class="ml-4"/>
      </button>
    </div>  

    <div v-if="!isEmpty" class="flex justify-between">
      <div class="flex justify-start">
        <h3 class="text-gray-700 ml-1 pt-1 text-lg font-base mr-2">
          Filtrovat podle kategorie:
        </h3>
        <div>
          <select @change="addFilteredCategory" class="search-filter-select">
              <option value="0" class="hidden bg-white" selected>Vyberte kategorii...</option>
              <option v-for="cat in availabeCategories" :key="cat.categories_id" 
              :value="cat.categories_id"
              class="my-10 text-gray-700 bg-white">{{ cat.name }}</option>
          </select>
        </div>
        <div 
          v-for="cat in activeCategories" :key="cat.categories_id"
          @click="removeFilteredCategory(cat.categories_id)"
          class="search-category-chip">
          <div class="flex-initial max-w-full px-1 pt-0.5 leading-none text-sm">{{ cat.name }}</div>
          <fa icon="xmark" class="ml-2 pr-1 pt-1" size="xs"/>
        </div>
      </div>

      <div class="flex justify-start" v-if="onChangedReceivers">
        <h3 class="text-gray-700 ml-1 pt-1 text-lg font-base mr-2">
          Změny za poslední:
        </h3>
        <div>
          <select @change="setTimeInterval" class="search-filter-select">
              <option value="a" class="hidden bg-white" selected>Vyberte interval...</option>
              <option v-for="time in timeIntervals" :key="time.val" 
              :value="time.val"
              class="my-10 text-gray-700 bg-white">{{ time.text }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  props: ["isEmpty"],
  data() {
    return {
      categoriesIdsToFilter: [],
      search: "",
      timeIntervals: [{
        val: 'h',
        text: '1 hodinu'
      },{
        val: 'd',
        text: '1 den'
      },{
        val: 'w',
        text: '1 týden'
      },{     
        val: 'a',
        text: 'všechno'
      }],
    };
  },
  watch: {
    isClearNeeded() {
      this.search = "";
      this.$store.dispatch("setSearchClearNeeded", false);
      this.sendFilteredReceivers();
    },
    isFilterResetNeeded() {
      this.sendFilteredReceivers();
      this.$store.dispatch('setFilterResetNeeded', false);
    }
  },
  computed: {
    availabeCategories() {
      let allCategories = this.$store.getters.categories;
      let availabeCategories = []

      for (let i = 0; i < allCategories.length; i++) {
        if (!this.categoriesIdsToFilter.includes(allCategories[i].categories_id)) {
          availabeCategories.push(allCategories[i]);
        }
      }
      return availabeCategories;
    },
    onChangedReceivers() {
      if (this.$store.getters.currentList == 'changed') {
        return true;
      }
      return false;
    },
    // get active categories
    activeCategories() {
      const allCategories = this.$store.getters.categories;
       
      let activeCategories = allCategories.filter(x => {
        return this.categoriesIdsToFilter.includes(x.categories_id);
      })
      return activeCategories;
    },
    sortDesc() {
      return this.$store.getters.sortDesc;
    },
    isClearNeeded() {
      return this.$store.getters.searchClearNeeded;
    },
    isFilterResetNeeded() {
      return this.$store.getters.filterResetNeeded;
    },
    // get receivers 
    receivers() {
      // if on empty receivers page get empty receivers
      // if on non empty receivers filter them based on active filters
      if (this.isEmpty) {
        return this.$store.getters.emptyReceivers;
      } else {

        let recs = [];

        if (this.$store.getters.currentList == 'changed') {
          recs = this.$store.getters.changedReceivers;
        } else {
          recs = this.$store.getters.nonEmptyReceivers;
        }

        let filteredReceivers = [];

        // filter non empty receivers based on active categories filters
        if (this.categoriesIdsToFilter.length != 0) {
          for (let i=0; i < recs.length; i++) {
            for (let j=0; j < recs[i].categories.length; j++) {
              if (this.categoriesIdsToFilter.includes(recs[i].categories[j].categories_id)) {
                filteredReceivers.push(recs[i]);
              }
            }
          }        
        } else {


          return recs;
        }
        return filteredReceivers;
      }
    },
    // get filtered receivers based on input search
    filteredReceivers() {
      // if on empty receivers page only filter by receivers name
      // if on non empty receivers page filter by receivers name and by its categories
      if (this.isEmpty) {
        return this.receivers.filter((receiver) => {
          return receiver.name
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      } else {
        const nonEmptyReceivers = this.receivers;

        let filteredReceivers = []
        for (let i=0; i < nonEmptyReceivers.length; i++) {
          if (nonEmptyReceivers[i].name.toLowerCase().includes(this.search.toLowerCase())) {
            filteredReceivers.push(nonEmptyReceivers[i]);
            continue;
          } 
          for (let j=0; j < nonEmptyReceivers[i].categories.length; j++) {
            if (nonEmptyReceivers[i].categories[j].name.toLowerCase().includes(this.search.toLowerCase())) {
              filteredReceivers.push(nonEmptyReceivers[i]);
              break;
            }
          }
        }
        return filteredReceivers;
      }
    },
  },
  methods: {
    // test() {
    //   //const myDatetime = this.filteredReceivers[0].changes[0].changed_at;
    //   //const myDatetimeObj = new Date(myDatetime)

    //   const oneHour = new Date();
    //   const oneDay = new Date();
    //   const oneWeek = new Date();
    //   oneHour.setDate(oneHour.getHours() - 1);
    //   oneDay.setDate(oneDay.getDate() - 1);
    //   oneWeek.setDate(oneWeek.getDate() - 7);
      
    //   const now = new Date();


    //   if (now.getTime() > oneWeek.getTime()) {
    //     alert(oneDay + ' je novsi ako ' + oneWeek);
    //   }
    // },
    setTimeInterval(event) {
      this.$store.dispatch('setActiveInterval', event.target.value)
    },
    addFilteredCategory(event) {
      this.categoriesIdsToFilter.push(parseInt(event.target.value, 10));
      this.$emit("send-filtered-receivers", this.receivers, true);
      this.sendFilteredReceivers(true);
      event.target.options[0].selected = true;
    },
    removeFilteredCategory(cat_id) {
      this.categoriesIdsToFilter.splice(this.categoriesIdsToFilter.indexOf(cat_id), 1);
      this.$emit("send-filtered-receivers", this.receivers, true);
      this.sendFilteredReceivers();
    },
    // send filtered receivers to the parent
    sendFilteredReceivers() {
      if (this.search.trim() == ''){
        if (this.categoriesIdsToFilter.length == 0) {
          this.$emit("send-filtered-receivers", this.filteredReceivers, true, true);
        } else {
          this.$emit("send-filtered-receivers", this.filteredReceivers, true, false);
        }
      } else {
        if (this.categoriesIdsToFilter.length == 0) {
          this.$emit("send-filtered-receivers", this.filteredReceivers, false, true);
        } else {
          this.$emit("send-filtered-receivers", this.filteredReceivers, false, false);
        }
      }
    },
    // sort receivers alphabeticaly ascending or descending
    async sortReceivers() {
      const sortedReceivers = Object.assign([], this.$store.getters.receivers);

      if (this.sortDesc) {
        sortedReceivers.sort((a, b) => {
          let fn1 = a.name.toLowerCase();
          let fn2 = b.name.toLowerCase();

          if (fn1 < fn2) {
            return -1;
          }
          if (fn1 > fn2) {
            return 1;
          }
          return 0;
        });
      } else {
        sortedReceivers.sort((a, b) => {
          let fn1 = a.name.toLowerCase();
          let fn2 = b.name.toLowerCase();

          if (fn1 > fn2) {
            return -1;
          }
          if (fn1 < fn2) {
            return 1;
          }
          return 0;
        });
      }
      this.$store.dispatch('getSortedReceivers', sortedReceivers);
      this.$store.dispatch('setSortDesc');
      await this.sendFilteredReceivers()
    },
  },
};
</script>