const API_URL = process.env.VUE_APP_API_URL;

export default {
  setActiveInterval(context, payload) {
    context.commit('setActiveInterval', payload);
  },
  setSortDesc(context) {
    context.commit('setSortDesc');
  },
  setSearchClearNeeded(context, payload) {
    context.commit('searchClearNeeded', payload);
  },
  setFilterResetNeeded(context, payload) {
    context.commit('filterResetNeeded', payload);
  },
  setOnEmpty(context, payload) {
    context.commit('setOnEmpty', payload);
  },
  setCurrentList(context, payload) {
    context.commit('setCurrentList', payload);
  },
  setCredentialsError(context) {
    context.commit('setCredentialsErrorToFalse');
  },
  setTmpScrollHeight(context, payload) {
    context.commit('setTmpScrollHeight', payload);
  },
  async recommendCategory(context, payload) {
    var url = new URL(API_URL + "/recommendations")

    var params = {
      receiver: payload
    };
    
    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    
    const responseData = await response.json();

    context.commit('setRecommendedCategory', responseData.recommended_category_id);
    
    console.log(responseData);
  },
  async getToken(context, payload) {
    context.state.credentialsError = false;

    const formData = new FormData();
    formData.append("username", payload.username);
    formData.append("password", payload.password);
    formData.append("grant_type", "password");

    const response = await fetch(API_URL + "/token", {
      method: "POST",
      body: formData,
    });
    const responseData = await response.json();

    if (response.ok) {
      context.commit('setToken', responseData.access_token);
      context.commit('setUser', payload.username);
    } else {
      context.state.credentialsError = true;
    }

    const userIdRsponse = await fetch(API_URL + '/users/me', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${context.getters.token}`,
      },
    });

    const userIdRsponseData = await userIdRsponse.json();
    
    if (userIdRsponse.ok) {
      context.commit('setUserId', userIdRsponseData.users_id);
    } else {
      context.state.credentialsError = true;
    }
    
  },
  async removeCategory(context, payload) {
    const response = await fetch(API_URL + '/categories/', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${context.getters.token}`,
      },
      body: JSON.stringify({
        "receiver_id": payload.receiver_id,
        "category_id": payload.category_id,
      })
    });
    
    const responseData = await response.json();
    console.log(responseData);
  },
  async removeReceiver(context, payload) {
    const response = await fetch(API_URL + '/receivers/' + payload.rec_id, {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${context.getters.token}`
      }
    });

    context.state.isLoading = true;
    const responseData = await response.json();
    console.log(responseData);

    context.state.isLoading = false;
  },
  async addCategory(context, payload) {
    // const response = 
    await fetch(API_URL + '/categories/', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${context.getters.token}`,
      },
      body: JSON.stringify({
        "receiver_id": payload.receiver_id,
        "category_id": payload.category_id,
      })
    });

    // const responseData = await response.json();
    // console.log(responseData);
  },
  async addChange(_, payload) { 
    // const response = 
    await fetch(API_URL + '/changes/', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "receivers_id": payload.receivers_id,
        "users_id": payload.users_id,
      })
    });

    // const responseData = await response.json();
    
  },
  async updateReceiversName(_, payload) {
    let url = new URL(API_URL + '/receivers/' + payload.receivers_id);
    let params = { new_name: payload.new_name } 

    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
      method: "POST", 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const responseData = await response.json();
    console.log(responseData);
  },
  getSortedReceivers(context, payload) {
    context.commit('getReceivers', payload);
  },
  // async getReceivers(context) {

  //   context.state.isLoading = true;

  //   const response = await fetch('http://localhost:8000/receivers-with-categories/');
    
  //   const responseData = await response.json();
    
  //   if (!response.ok) {
  //     const error = new Error(responseData.message || 'Chyba');
  //     throw error;
  //   }
    
  //   context.commit('getReceivers', responseData.receivers);

  //   context.state.isLoading = false;
  //   context.commit('setInitialLoaded');
  // },
  async getReceivers(context) {

    context.state.isLoading = true;

    const response = await fetch(API_URL + '/receivers-with-categories-and-changes/');
    
    const responseData = await response.json();
    
    if (!response.ok) {
      const error = new Error(responseData.message || 'Chyba');
      throw error;
    }
    
    context.commit('getReceivers', responseData.receivers);

    context.state.isLoading = false;
    context.commit('setInitialLoaded');
  },
  async getCategories(context) {
    const response = await fetch(API_URL + '/categories/');
    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || 'Chyba');
      throw error;
    }

    context.commit('getCategories', responseData);
  },
  signOut(context) {
    context.commit('signOut');
  }
};