<template>
  <div class="px-8 py-4">
    <modal-remove-receiver 
      v-if="open" 
      @closed="open = false" 
      :receiverName="receiverName"
      :receiverId="receiverId">
    </modal-remove-receiver>
    <search-bar @send-filtered-receivers="getFilteredReceivers" :isEmpty="true" ></search-bar>    
    <div class="overflow-auto rounded-lg shadow">
      <table class="w-full table-fixed">
        <thead class="table-head">
          <tr>
            <th class="rec-list-th w-8 text-left"></th>
            <th class="rec-list-th w-12 text-left">ID</th>
            <th class="rec-list-th text-left">Příjemce</th>
            <th class="rec-list-th w-52 text-center">Kategorie</th>
            <th class="rec-list-th w-32 text-center"></th>
            <th class="rec-list-th w-24 text-center"></th>
          </tr>
        </thead>
      </table>
      <div ref="tablebody" v-if="!this.$store.getters.isLoading" class="overflow-y-auto table-wrapper">
        <div v-for="(receiver, index) in emptyReceivers" :key="receiver.id"
        class="table flex-row justify-between w-full">
          <div class="table-content-cell w-8 tr-index ">{{ index + 1 }}</div>
          <div class="table-content-cell w-12 tr-index font-bold ">{{ receiver.receivers_id }}</div>
          <div class="table-content-cell tr-name " >{{ receiver.name }}</div>
          <div class="table-content-cell w-52 text-center tr-categories ">Bez kategorie</div>
          <div class="w-32 text-gray-700 table-cell tr-button align-middle">
            <router-link v-if="this.$store.getters.isLoggedIn" 
              :to="`/empty_receivers/${receiver.receivers_id}`" 
              :style="{ 'background-color': `${bgColor}`}" 
              class="table-button hover:bg-blue-700">
                Upravit
            </router-link>
            <router-link v-else 
              :to="{ name: 'LoginFromEdit', params: { rec_id: receiver.receivers_id, fromEmpty: 'false' } }" 
              class="table-button router-link-a">
                Upravit
            </router-link>
          </div>
          <div class="w-24 table-cell tr-button align-middle text-center">
            <a v-if="this.$store.getters.isLoggedIn" @click="openModalAndPassReceiverInf(receiver.name, receiver.receivers_id)" class="button-danger uppercase">
              <fa icon="trash-can"/>
            </a>
            <router-link v-else 
              to="/login"
              class="button router-link-a">
              <fa icon="trash-can"/>
            </router-link>
          </div>
        </div>
      </div>

      <clip-loader v-else :color="'#1d4fd8'" :size="'74px'" class="py-6"></clip-loader>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import SearchBar from "../components/SearchBar.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import ModalRemoveReceiver from "../components/ModalRemoveReciever.vue"

export default {
  components: {
    SearchBar,
    ClipLoader,
    ModalRemoveReceiver,
  },
  data() {
    return {
      filteredReceivers: [],
      emptySearchInput: true,
      open: false,
      receiverName: null,
      receiverId: null,
    };
  },
  methods: {
    openModalAndPassReceiverInf(recName, recId) {
      this.open = true;
      this.receiverName = recName;
      this.receiverId = recId;
    },
    async test() {
      const payload = 'Správa jeskyní ČR/SK';

      await this.$store.dispatch('recommendCategory', payload);

      console.log(this.$store.getters.categories);

      const categories = this.$store.getters.categories;

      const rec_cat = categories.find( ({ categories_id }) => categories_id ===  this.$store.getters.recommendedCategory); 

      console.log('Odporucana kategoria: ', rec_cat);
      
    },
    getFilteredReceivers(filteredReceivers, emptySearchInput) {
      this.filteredReceivers = filteredReceivers;
      this.emptySearchInput = emptySearchInput;
    },
  },
  computed: {
    emptyReceivers() {
      if (this.emptySearchInput) {
        return this.$store.getters.emptyReceivers;
      }
      return this.filteredReceivers;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    bgColor() {
      return !this.$store.getters.isLoggedIn ? 'rgba(107,114, 128, 0.4)' : 'rgb(59, 130, 246)';
    },
  },
  mounted() {
    this.$store.dispatch('setOnEmpty', true);
    this.$store.dispatch('setCurrentList', 'empty');
  }
};
</script>

<style scoped>
.scroll-margin {
  padding-right: 23px;
}

.table-wrapper {
  max-height: 70vh;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background: rgb(59, 130, 246);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(27, 110, 243);
}

.table-wrapper>div:nth-child(even) {
  background-color: rgb(243 244 246);
}

.router-link-a {
  background-color: rgba(107,114, 128, 0.4)
}

.router-link-a:hover {
  background-color: rgba(107,114, 128, 0.5)
}
</style>