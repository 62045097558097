<template>
    <div class="flex justify-center items-center my-2 mx-2 ">
      <div class="flex justify-center w-10/12" v-show="!editing">
        <div class="w-1/12 ml-2 text-blue-500 text-xl pt-1 items-center self-center">
          {{ recId }}
        </div>
        <div class="w-10/12 editable-name">
          {{ recName }}
        </div>
        <div class="w-1/12 ml-2 pt-1 items-center self-center" @click="startEditing">
          <fa icon="pen-to-square" size="lg" class="editable-icon"/>
        </div>
      </div>
      <span v-show="editing" class="w-full">
        <textarea ref="textarea" class="editable-textarea" 
          contenteditable
          v-model="localRecName"
          onfocus='this.style.height = "5px";this.style.height = this.scrollHeight + "px"'
          oninput='this.style.height = "5px";this.style.height = this.scrollHeight + "px"'
          @keydown.enter="finishEditing($event.target.value)">
        </textarea>    
      </span>
    </div>

    <div v-if="editing" class="flex justify-center">
      <button @click="$emit('finish')" class="edit-button mr-2">zpět</button>
      <button @click="finishEditing(textAreaValue)" class="edit-button ml-2">uložit</button>
    </div>


    <!-- <div v-if="editing" class="flex justify-center">
      <fa @click="finishEditing(textAreaValue)" icon="check" size="lg" class="text-green-500 mr-3 p-2 border-2 w-5 border-green-500 rounded-3xl hover:border-0 hover:bg-green-500 hover:text-white cursor-pointer"/>
      <fa @click="$emit('finish')" icon="xmark" size="lg" class="inline-block text-red-500 p-2 px-3 border-2 border-red-500 rounded-3xl hover:border-0 hover:bg-red-500 hover:text-white cursor-pointer"/>
    </div> -->


</template>

<script>
export default {
  props: ["recName", "editing", "recId"],
  emits: ["finishedEditing", "sendInputToParent", "setEditingToFalse", "finish"],
  data() {
    return {
      localEditing: this.editing,
      localRecName: this.recName,
      initialRecName: this.recName,
    }
  },
  computed:  {
    textAreaValue() {
      return this.$refs.textarea.value;
    }
  },
  watch: {
    editing() {
      this.localEditing = false;
      this.localRecName = this.recName;
    },
  },
  methods: {
    async startEditing() {
      this.localEditing = true
      await this.$emit('setEditingToFalse');
      this.$refs.textarea.focus();
    },
    finishEditing() {
      //console.log('Val: ' + val);
      //console.log('LocalRecName: ' + this.localRecName);
      this.$emit('sendInputToParent', this.localRecName);
      this.$emit('finishedEditing', this.localRecName);
      this.localEditing = false;
      this.$emit('setEditingToFalse');
    },
  },
};

</script>

<style scoped>

.edit-button {
  display: block;
  width: 6rem;
  margin-bottom: 0.25rem;
  background-color: rgb(59, 130, 246);
  color: white;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
  text-transform: uppercase;
}

.edit-button:hover {
  background-color: rgb(29, 78, 216);
}

.edit-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

</style>