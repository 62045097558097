import { createRouter, createWebHistory } from 'vue-router';

import ReceiversList from '../pages/ReceiversList.vue';
import EmptyReceiversList from '../pages/EmptyReceiversList.vue';
//import ChangedReceiversList from '../pages/ChangedReceiversList.vue'; 

import ChangedReceiversList from '../pages/ChangedReceiversList.vue';

import LoginPage from '../pages/LoginPage.vue';
import ModalWindow from '../components/ModalWindow.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/empty_receivers' },
    { path: '/empty_receivers', component: EmptyReceiversList, children: [
      { path: ':rec_id', component: ModalWindow, props: { from: 'empty' } }
    ]},
    { path: '/receivers', component: ReceiversList, children: [
      { path: ':rec_id', component: ModalWindow, props: { from: 'nonEmpty' } }
    ]},
    { path: '/changed_receivers', component: ChangedReceiversList, children: [
      { path: ':rec_id', component: ModalWindow, props: { from: 'changed' } }
    ]},
    { path: '/login', component: LoginPage },
    { path: '/login/:rec_id', name: 'LoginFromEdit', component: LoginPage, props: true },
    { path: '/:notFound(.*)', component: null },
  ],
});

export default router;