<template>
  <header class="header-header">
    <div class="w-1/3 flex ml-3">
      <div class="w-84 p-6">
        <span class=" font-semibold uppercase ">Párování příjemců</span>
      </div>
    </div>
    <div class="flex w-1/3 justify-center">
      <div class="w-84 p-5">
        <router-link class="header-button" to="/empty_receivers">Noví příjemci</router-link>
      </div>
      <div class="w-84 p-5">
        <router-link class="header-button" to="/receivers">Stávající příjemci</router-link>
      </div>
      <div class="w-84 p-5">
        <router-link class="header-button" to="/changed_receivers">Upravení příjemci</router-link>
      </div>
    </div>
    <div class="w-1/3 flex justify-end">
      <div class="p-5 pr-12 mt-1.5">
        <span class="mr-2 font-medium">{{ loggedUser }}</span>
        <router-link class="header-logout-btn" 
          v-if="isLoggedIn" 
          :to="redirectPath" @click="signOut">
          <fa icon="user-slash" size="xs"/> 
          Odhlásit se
        </router-link>
        <router-link class="header-login-btn" 
          v-else to="/login">
          <fa icon="user" size="xs" class="mr-1"/>
          Přihlásit se
        </router-link>
      </div>
    </div>
  </header>
  <div v-if="onLoginPage" class="w-3/5">
    <p class="mx-8 mt-4 text-blue-500 font-medium">
      Aplikace pro párování příjemců dotačních výzev do příslušných kategorií.
      Pro párování nových příjemců klikněte na tlačítko <span class="font-bold">Noví příjemci</span>.
      Pro párování stávajících příjemců klikněte na tlačítko <span class="font-bold">Stávající příjemci</span>. 
      Pro zobrazení nedávno změněných příjemců klikněte na tlačítko <span class="font-bold">Upravení příjemci</span>.
    </p>
  </div>
</template>

<script>
export default {
  methods: {
    signOut() {
      this.$store.dispatch('signOut');
    }
  },
  computed: {
    onLoginPage() {
      if (this.$route.path.includes('/login')){
        return false;
      }
      return true;
    },
    redirectPath() {
      //console.log(this.$store.getters.currentList);
      if (this.$store.getters.currentList == 'empty') {
        return '/';
      } else if (this.$store.getters.currentList == 'nonEmpty') {
        return '/receivers';
      } else {
        return '/changed_receivers';
      }
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    loggedUser() {
      return this.$store.getters.user;
    }
  }
}
</script>

<style scoped>

.header-button.router-link-active,
.header-button.router-link-exact-active {
  background-color: rgb(78, 143, 247)
}

a:hover {
  background-color: rgb(78, 143, 247)
}
</style>