<template>
  <div class="px-8 py-4">
    <modal-remove-reciever
      v-if="open" 
      @closed="open = false" 
      :receiverName="receiverName"
      :receiverId="receiverId">
    </modal-remove-reciever>
    <search-bar @send-filtered-receivers="getFilteredReceivers" :isEmpty="false"></search-bar>
    <div class="overflow-auto rounded-lg shadow">
      <table class="w-full table-fixed">
        <thead class="table-head">
          <tr>
            <th class="rec-list-th w-8 text-left"></th>
            <th class="rec-list-th w-12 text-left">ID</th>
            <th class="rec-list-th text-left">Příjemce</th>
            <th class="rec-list-th text-center w-52">Datum úpravy</th>
            <th class="rec-list-th text-center w-52">Kategorie</th>
            <th class="rec-list-th text-center w-32"></th>
            <th class="rec-list-th text-center w-24"></th>
          </tr>
        </thead>
      </table>
      <div ref="tablebody" v-if="!this.$store.getters.isLoading" class="overflow-y-auto table-wrapper">
        <div v-for="(receiver, index) in changedReceivers" :key="receiver.id"
        class="table flex-row justify-between w-full">
          <div class="table-content-cell w-8 tr-index">{{ index + 1 }}</div>
          <div class="table-content-cell w-12 tr-index font-bold ">{{ receiver.receivers_id }}</div>
          <div class="table-row-cell tr-name" >{{ receiver.name }}</div>
          <div class="table-row-cell w-52 text-center" >
            <span class="block">{{ formattedDate(receiver.changes) }}</span>
            <span class="block">{{ formattedTime(receiver.changes) }}</span>
          </div>
          <div class="table-row-cell tr-categories text-center w-52">
            <div v-if="receiver.categories!=0">
              <div class="my-2.5" v-for="category in receiver.categories" :key="category.id">
                <span class="table-cell-chip">{{ category.name }}</span>
              </div>
            </div>
            <div v-else>Bez kategorie</div>
          </div>
          
          <div class="w-32 text-gray-700 table-cell tr-button align-middle">
            <router-link v-if="this.$store.getters.isLoggedIn" 
              :to="`/changed_receivers/${receiver.receivers_id}`" 
              :style="{ 'background-color': `${bgColor}`}" 
              class="table-button hover:bg-blue-700" >
                Upravit
            </router-link>
            <router-link v-else 
              :to="{ name: 'LoginFromEdit', params: { rec_id: receiver.receivers_id, fromEmpty: 'false'} }" 
              class="router-link-a table-button">
                Upravit
            </router-link>
          </div>
          <div class="w-24 table-cell tr-button align-middle text-center">
            <a v-if="this.$store.getters.isLoggedIn" @click="openModalAndPassReceiverInf(receiver.name, receiver.receivers_id)" class="button-danger uppercase">
              <fa icon="trash-can"/>
            </a>
            <router-link v-else 
              to="/login"
              class="button router-link-a">
              <fa icon="trash-can"/>
            </router-link>
          </div>
        </div>
      </div>
      <clip-loader v-else :color="'#1d4fd8'" :size="'74px'" class="py-6"></clip-loader>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import SearchBar from '../components/SearchBar.vue';
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import ModalRemoveReciever from "../components/ModalRemoveReciever.vue";

export default {
  components: {
    SearchBar,
    ClipLoader,
    ModalRemoveReciever,
  },
  data() {
    return {
      filteredReceivers: [],
      emptySearchInput: true,
      noCategorySelected: true,
      open: false,
      receiverName: null,
      receiverId: null,
    }
  },
  methods: {
    openModalAndPassReceiverInf(recName, recId) {
      this.open = true;
      this.receiverName = recName;
      this.receiverId = recId;
    },
    async test() {
      const payload = 'Správa jeskyní ČR/SK';

      await this.$store.dispatch('recommendCategory', payload);

      console.log(this.$store.getters.categories);

      const categories = this.$store.getters.categories;

      const rec_cat = categories.find( ({ categories_id }) => categories_id ===  this.$store.getters.recommendedCategory); 

      console.log('Odporucana kategoria: ', rec_cat);
      
    },
    formattedDate(changes) {
      const latestChange = this.latestChange(changes);
      
      const day = latestChange.getDate().toString();
      const month = (latestChange.getMonth() + 1).toString();
      const year = latestChange.getFullYear().toString();

      return day + '.' + month + '.' + year;
    },
    formattedTime(changes) {
      const latestChange = this.latestChange(changes);

      const hours = latestChange.getHours().toString();
      const minutes = latestChange.getMinutes().toString();

      if (latestChange.getMinutes() < 10){
        return hours + ':0' + minutes;
      } else {
        return hours + ':' + minutes;
      }
    },
    getFilteredReceivers(filteredReceivers, emptySearchInput, noCategorySelected) {
      this.filteredReceivers = filteredReceivers;
      this.emptySearchInput = emptySearchInput;
      this.noCategorySelected = noCategorySelected;
    },
    async removeReceiver(rec_id) {
      const payload = {
        rec_id: rec_id
      }
      await this.$store.dispatch('removeReceiver', payload);
      await this.$store.dispatch('getReceivers');
      await this.$store.dispatch('setFilterResetNeeded', true);
    },
    latestChange(changes) {
      let datetimes = []
      for (let i=0; i<changes.length; i++) {
        let test = new Date(Date.parse(changes[i].changed_at));
        datetimes.push(test)
      }
      
      datetimes.sort((date1, date2) => date1 - date2);
      datetimes.reverse()

      return datetimes[0];
    }
  },
  computed: {
    activeInterval() {
      return this.$store.getters.activeInterval;
    },
    changedReceivers() {
      const oneHour = new Date();
      const oneDay = new Date();
      const oneWeek = new Date();
      let today = new Date();

      Date.prototype.addHours = function(h) {
        this.setTime(this.getTime() - (h*60*60*1000));
        return this;
      }

      oneHour.addHours(1);
      oneDay.setDate(today.getDate() - 1);
      oneWeek.setDate(today.getDate() - 7);

      let finalRecs = [];

      if (this.emptySearchInput && this.noCategorySelected) {
        finalRecs = [...this.$store.getters.changedReceivers];
      } else {
        finalRecs = [...this.filteredReceivers];
      }

      let finalRecsInInterval = [];

      for (let i=0; i<finalRecs.length; i++){
        const myTime = new Date(this.latestChange(finalRecs[i].changes));

        switch (this.activeInterval) {
          case 'a':
            finalRecsInInterval.push(finalRecs[i]);
            break;
          case 'h':
            if (myTime > oneHour.getTime()) {
              finalRecsInInterval.push(finalRecs[i]);
            }
            break;
          case 'd':
            if (myTime > oneDay.getTime()) {
              finalRecsInInterval.push(finalRecs[i]);
            }
            break;
          case 'w':
            if (myTime > oneWeek.getTime()) {
              finalRecsInInterval.push(finalRecs[i]);
            }
            break;
          default:
            finalRecsInInterval.push(finalRecs[i]);
        }
      }
      return finalRecsInInterval;
    },
    bgColor() {
      return !this.$store.getters.isLoggedIn ? 'rgba(107,114, 128, 0.4)' : 'rgb(59, 130, 246)';
    },
  },
  mounted() {
    this.$store.dispatch('setOnEmpty', false);
    this.$store.dispatch('setCurrentList', 'changed');
    this.$store.dispatch('setActiveInterval', 'a');
  },
}
</script>

<style scoped>
.scroll-margin {
  padding-right: 23px;
}

.table-wrapper {
  max-height: 65vh;
}

::-webkit-scrollbar {
  width: 6px;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 

::-webkit-scrollbar-thumb {
  background: rgb(59, 130, 246);
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  background: rgb(27, 110, 243);
}

.table-wrapper>div:nth-child(even) {
  background-color: rgb(243 244 246);
}

.router-link-a {
  background-color: rgba(107,114, 128, 0.4)
}

.router-link-a:hover {
  background-color: rgba(107,114, 128, 0.5)
}
</style>
