export default {
  sortDesc(state) {
    return state.sortDesc;
  },
  searchClearNeeded(state) {
    return state.searchClearNeeded;
  },
  credentialsError(state) {
    return state.credentialsError;
  },
  token(state) {
    return state.token;
  },
  user(state) {
    return state.user;
  },
  user_id(state) {
    return state.user_id;
  },
  receivers(state) {
    return state.receivers;
  },
  categories(state) {
    return state.categories;
  },
  emptyReceivers(state) {
    return state.receivers.filter(receiver => receiver.categories.length == 0);
  },
  nonEmptyReceivers(state) {
    return state.receivers.filter(receiver => receiver.categories.length > 0);
  },
  changedReceivers(state) {
    return state.receivers.filter(receiver => receiver.changes.length > 0);
  },
  currentReceiverCategories(state) {
    return state.currentReceiverCategories;
  },
  isLoggedIn(state) {
    return !!state.token;
  },
  isLoading(state) {
    return state.isLoading;
  },
  tmpScrollHeight(state) {
    return state.tmpScrollHeight;
  },
  tmpClientHeight(state) {
    return state.tmpClientHeight;
  },
  initialLoaded(state) {
    return state.initialLoaded;
  },
  filterResetNeeded(state) {
    return state.filterResetNeeded;
  },
  onEmpty(state) {
    return state.onEmpty;
  },
  currentList(state) {
    return state.currentList;
  },
  activeInterval(state) {
    return state.activeInterval;
  },
  recommendedCategory(state) {
    return state.recommendedCategory;
  }
};