<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click.self="close">
      <div class="modal-container text-right">
        <button @click="close">
          <fa icon="xmark" size="lg"/>
        </button>
        <div class="modal-body" @click.self="closeEditing">
          <div>
            <editable @finishedEditing="updateReceiversNameGlobally(receiversName)" 
                      @finish="closeEditing"
                      @sendInputToParent="updateReceiversNameLocally"  
                      :recName="receiversName"
                      :recId="recId"
                      :editing="editing"
                      @setEditingToFalse="editing = !editing">
            </editable>
          </div>
          
          <div v-if="!editing" class="w-2/4 mx-auto">
            <ul class="my-3 mx-auto">
              <li v-for="cat in getCurrentReceiversCategories" :key="cat.categories_id" class="mb-3 uppercase">
                <div
                  class="remove-list modal-remove-list"
                  @click="removeCategory(cat.categories_id)">
                  <span class="w-1/6"></span>
                  <span class="w-4/6">{{ cat.name }}</span>
                  <span class="w-1/6 text-right pr-4"><fa icon="minus" /></span>
                </div>
              </li>
            </ul>

            <!-- LOADING SPINNER -->
            <div 
              v-if="predictingCategory" 
              class="flex my-3 bg-white justify-center text-blue-500 rounded border-blue-500 border text-center p-1 m-1 tracking-wide">
              <clip-loader :size="'18px'" :color="'#1d4fd8'" class="mt-0.5">
              </clip-loader>
            </div>

            <!-- RECOMMENDED CATEGORY BUTTON -->
            <div 
              v-if="getCurrentReceiversCategories.length==0 && !predictingCategory" 
              @click="addCategory(recommendedCategory.categories_id)" 
              class="flex my-3 bg-white justify-center text-blue-500 rounded border-blue-500 border cursor-pointer text-center p-1 m-1 tracking-wide hover:bg-blue-500 hover:text-white">
              Přidat doporučenou kategorii&nbsp; <span class="font-bold">{{ recommendedCategoryName }}</span>
            </div>
            
            <details v-if="!noAvailableCategories" class="mx-auto">
              <summary @click="showDetail" class="add-list modal-summary">
                <span class="w-1/6 cursor-pointer"></span>
                <span class="w-4/6 cursor-pointer border-none">Přidat jinou kategorii</span>
                <span class="w-1/6 text-right pr-4 cursor-pointer"><fa icon="plus" /></span>
              </summary>
              <ul v-if="selectingNewCategory" class="custom-select modal-new-category-list">
                <li
                  class="hover:bg-green-100"
                  v-for="category in getAvailableCategories"
                  :key="category.categories_id"
                  :value="category.categories_id"
                  :style="recommendedCategoryStyle(category.categories_id, recommendedCategory)"
                  @click="addCategory(category.categories_id)">
                  {{ category.name }}
                </li>
              </ul>
            </details>
          </div>
          <div v-if="!editing">
            <router-link v-if="from=='nonEmpty'" to="/receivers/" class="modal-button"> hotovo </router-link>
            <router-link v-if="from=='empty'" to="/empty_receivers/" class="modal-button"> hotovo </router-link>
            <router-link v-if="from=='changed'" to="/changed_receivers/" class="modal-button"> hotovo </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editable from "./Editable.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  components: {
    'editable': Editable,
    'clip-loader': ClipLoader,
  },
  props: ["from"],
  data() {
    return {
      receiversName: this.getReceiversName(this.$route.params.rec_id),
      recId: this.$route.params.rec_id,
      selected: "",
      editing: false,
      selectingNewCategory: false,
      predictingCategory: false,
      lastCategoryRemoved: false,
    };
  },
  watch: {
    lastCategoryRemoved(newVal) {
      console.log('Category recommendation process needed...');
      if (newVal === true) {
        this.getRecommendedCategory();
      }
    }
  },
  methods: {
    test() {
      console.log(this.$store.getters.recommendedCategory);
    },
    recommendedCategoryStyle(categoryIndex, recommendedCategoryIndex) {      
      let style = {};
      
      if (categoryIndex == recommendedCategoryIndex.categories_id) { 
        style.fontWeight = 'bold';
      }
      
      if (this.getCurrentReceiversCategories.length == 0) {
        return style;
      }
      return null;
    },
    showDetail() {
      this.selectingNewCategory = true;
    },
    closeEditing() {
      this.editing = false;
      //this.receiversName
    },
    updateReceiversNameLocally(value) {
      this.receiversName = value; 
    },
    close() {
      if (this.from == 'empty') {
        this.$router.push('/empty_receivers/')
      } else if (this.from == 'nonEmpty') {
        this.$router.push('/receivers/')
      } else {
        this.$router.push('/changed_receivers/')
      }
    },
    // get receiver name based on receivers id
    getReceiversName(rec_id) {
      return this.$store.getters.receivers.filter(
        (rec) => rec.receivers_id == rec_id
      )[0].name.trim();
    },
    // remove category of the receiver based on category id 
    async removeCategory(category_id) {
      if (this.getCurrentReceiversCategories.length == 1) {
        this.lastCategoryRemoved = true;
      }

      const payload = {
        category_id: category_id,
        receiver_id: this.$route.params.rec_id,
      };

      await this.$store.dispatch("removeCategory", payload);
      //console.log('Mazem category: ' + payload);

      const change_payload = {
        users_id: this.$store.getters.user_id,
        receivers_id: this.$route.params.rec_id,
      }

      await this.$store.dispatch("addChange", change_payload);

      await this.$store.dispatch("getReceivers");

      // clear search input
      this.$store.dispatch("setSearchClearNeeded", true);

      this.lastCategoryRemoved = false;
    },
    async addCategory(category_id) {
      
      const payload = {
        category_id: category_id,
        receiver_id: this.$route.params.rec_id,
      };
      await this.$store.dispatch("addCategory", payload);

      const change_payload = {
        users_id: this.$store.getters.user_id,
        receivers_id: this.$route.params.rec_id,
      }

      await this.$store.dispatch("addChange", change_payload);

      await this.$store.dispatch("getReceivers");
      // clear search input
      this.$store.dispatch("setSearchClearNeeded", true);
      this.selectingNewCategory = false;
    },
    // update receiver name
    async updateReceiversNameGlobally(new_name) {
      const payload = {
        receivers_id: this.$route.params.rec_id,
        new_name: new_name,
      };
      await this.$store.dispatch("updateReceiversName", payload);

      const change_payload = {
        users_id: this.$store.getters.user_id,
        receivers_id: this.$route.params.rec_id,
      }

      await this.$store.dispatch("addChange", change_payload);

      await this.$store.dispatch("getReceivers");
      // clear search input
      this.$store.dispatch("setSearchClearNeeded", true);
    },
    async getRecommendedCategory() {
      this.predictingCategory = true;
      const payload = this.receiversName;
      await this.$store.dispatch("recommendCategory", payload);
      this.predictingCategory = false;
    }
  },
  computed: {
    recommendedCategoryName() {
      if (this.recommendedCategory) {
        return this.recommendedCategory.name; 
      } 
      return '';
    },
    recommendedCategory() {
      const categories = this.$store.getters.categories;


      console.log('RECOMMENDED CATEGORY: ');
      console.log(categories.find( ({ categories_id }) => categories_id ===  this.$store.getters.recommendedCategory));

      return categories.find( ({ categories_id }) => categories_id ===  this.$store.getters.recommendedCategory); 
    },
    // get list of categories of given receiver based on url query param
    getCurrentReceiversCategories() {
      return this.$store.getters.receivers.filter(
        (rec) => rec.receivers_id === +this.$route.params.rec_id
      )[0].categories;
    },
    // get only categories that are not already paired with given receiver
    getAvailableCategories() {
      let allCategories = this.$store.getters.categories;
      let currentCategories = this.$store.getters.receivers.filter(
        (rec) => rec.receivers_id === +this.$route.params.rec_id
      )[0].categories;
      let currentCategoriesIds = currentCategories.map(
        ({ categories_id }) => categories_id
      );
      let availabeCategories = [];

      for (let i = 0; i < allCategories.length; i++) {
        if (!currentCategoriesIds.includes(allCategories[i].categories_id)) {
          availabeCategories.push(allCategories[i]);
        }
      }
      return availabeCategories;
    },
    noAvailableCategories() {
      return this.getAvailableCategories.length > 0 ? false : true;
    }
  },
  created() {
    if (this.getCurrentReceiversCategories.length == 0) {
      this.getRecommendedCategory();
    }
  }
};
</script>

<style scoped>
.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.remove-list {
  background-color: #C82333;
} 
.remove-list:hover {
  background-color: #d63142;
}

.add-recommend {
  background-color: white;
  border: solid #218838 1px;
  color: #218838;
}

.add-recommend:hover {
  background-color: #25983e;
  color: white;
}

.add-list {
  border: 1px solid #218838;
  background-color: white;
  color: #218838;
  padding: 4px;
  /*background-color: #218838;*/
}

.add-list:hover {
  background-color: #25983e;
  color: white;
}

details {
	position: relative;
	margin: 0.25rem;
}

details[open] {
	z-index: 1;
}

summary {
	list-style: none;
}

summary::-webkit-details-marker {
	display: none;
}

details[open] summary:before {
	content: '';
	display: block;
	width: 100vw;
	height: 100vh;
	background: transparent;
	position: fixed;
	top: 0;
	left: 0;
}

.custom-select {
	width: 100%;
	position: absolute;
	top: calc(100%);
	left: 0;
	margin: 0;
	box-sizing: border-box;
	border-radius: 5px;
	max-height: 300px;
	overflow-y: auto;
}

.custom-select li {
  padding: 0.375rem; 
	border-bottom: 1px solid rgb(52, 211, 153);
}

.custom-select li:last-child {
	border-bottom: none;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 40%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.modal-body {
  text-align: center;
}
</style>