export default {
  setRecommendedCategory(state, payload) {
    state.recommendedCategory = payload;
  },
  setActiveInterval(state, payload) {
    state.activeInterval = payload;
  },
  setSortDesc(state) {
    state.sortDesc = !this.state.sortDesc;
  },
  searchClearNeeded(state, payload) {
    state.searchClearNeeded = payload;
  },
  filterResetNeeded(state, payload) {
    state.filterResetNeeded = payload;
  },
  setOnEmpty(state, payload) {
    state.onEmpty = payload;
  },
  setCurrentList(state, payload) {
    state.currentList = payload;
  },
  getReceivers(state, payload) {
    state.receivers = payload;
  },
  getCurrentReceiverCategories(state, payload) {
    state.currentReceiverCategories = payload.categories;
  },
  setToken(state, token) {
    state.token = token;
  },
  setUser(state, user) {
    state.user = user;
  },
  setUserId(state, user_id) {
    state.user_id = user_id;
  },
  signOut(state) {
    state.token = null;
    state.user = null;
  },
  getCategories(state, payload) {
    state.categories = payload.categories;
  },
  setCredentialsErrorToFalse(state) {
    state.credentialsError = false;
  },
  setTmpScrollHeight(state, payload) {
    state.tmpScrollHeight = payload.tmpScrollHeight;
    state.tmpClientHeight = payload.tmpClientHeight;
  },
  setInitialLoaded(state) {
    state.initalLoaded = !state.initalLoaded; 
  }
};