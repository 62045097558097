<template>
  <div class="grid place-items-center mt-16">
    <h1 class="text-3xl">Přihlášení</h1>
    <form @submit.prevent="login" class="login-form">
      <div class="form-control mb-4 ">
        <label for="username" class="login-label">Přihlašovací jméno</label>
        <input type="text" id="username" placeholder="Přihlašovací jméno" v-model.trim="username" class="login-input">
      </div>
      <div class="form-control mb-4">
        <label for="password" class="login-label">Heslo</label>
        <input type="password" id="password" placeholder="Heslo" v-model="password" class="mb-3 login-input">
      </div>
      <p v-if="credentialsError" class="login-error">Nesprávné přihlašovací údaje</p> 
      <p v-if="!formIsValid" class="login-error">Prosím, vyplňte formulář</p>
      <div class="flex items-center justify-center">
        <input value="Přihlásit se" type="submit" class="button"/>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    fromEmpty: {
      type: String
    }
  },
  data() {
    return {
      username: '',
      password: '',
      formIsValid: true,
    };
  },
  methods: {
    // check if form is filled out
    validateForm() {
      if (this.username === '' || this.password === '' ) {
        this.formIsValid = false;
      } 
    },
    // login 
    async login() {
      this.$store.dispatch('setCredentialsError');
      this.formIsValid = true;
      this.validateForm();

      if (this.formIsValid) {
        const data = {
          username: this.username,
          password: this.password
        }

        // send request with credentials for JWT token 
        await this.$store.dispatch('getToken', data);

        // check if username and password are valid, if not print error
        if (this.$store.getters.credentialsError) {
          console.log(this.$store.getters.credentialsError);
          return;
        }
        
        // if logging in from login button redirect to home page
        // otherwise redirect to specific receivers detail
        if (!this.$route.params.rec_id) {
          if (this.$store.getters.currentList == 'empty') {
            this.$router.push('/empty_receivers');
          } else if (this.$store.getters.currentList == 'nonEmpty'){
            this.$router.push('/receivers');
          } else {
            this.$router.push('/changed_receivers');
          }
          //this.$router.push('/')
        } else {
          if (this.$store.getters.currentList == 'empty') {
            this.$router.push(`/empty_receivers/${this.$route.params.rec_id}`);
          } else if (this.$store.getters.currentList == 'nonEmpty') {
            this.$router.push(`/receivers/${this.$route.params.rec_id}`);
          } else {
            this.$router.push(`/changed_receivers/${this.$route.params.rec_id}`);
          }
        }
      }
    },
  },
  computed: {
    credentialsError() {
      return this.$store.getters.credentialsError;
    }
  }
}
</script>