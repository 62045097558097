import './tailwind.css';

import { createApp } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faUserSlash, faMagnifyingGlass, faMinus, faPlus, faPenToSquare, faArrowUp, faArrowDown, faXmark, faTrashCan, faCheck, faBan } from '@fortawesome/free-solid-svg-icons';

import App from './App.vue';
import router from './router';
import store from './store';

library.add(faUser, faUserSlash, faArrowUp, faMagnifyingGlass, faMinus, faPlus, faPenToSquare, faArrowDown, faXmark, faTrashCan, faCheck, faBan);

const app = createApp(App);
app.component('fa', FontAwesomeIcon);
app.use(store); 
app.use(router);
app.mount('#app');
