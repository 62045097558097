import { createStore } from 'vuex'

import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

const store = createStore({
  state() {
    return {
      currentReceiverCategories: [],
      receivers: [],
      categories: [],
      loading: false,
      token: null,
      user: null,
      user_id: null,
      credentialsError: false,
      isLoading: false,
      searchClearNeeded: false,
      sortDesc: true,

      tmpScrollHeight: null,
      tmpClientHeight: null,

      initialLoaded: false,

      filterResetNeeded: false,

      onEmpty: false,
      currentList: '',

      activeInterval: 'a',

      recommendedCategory: 1,
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
});

export default store;
